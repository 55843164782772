import React, { useEffect, useState } from "react";
import { TopBar } from "../navigation/TopBar";
import UpgradeLicenseWindow from "../layout/UpgradeLicenseWindow";
import { UpgradeLicenseResponse } from "../../models/UpgradeLicenseModel";
import { UpgradeForm } from "../upgrade/UpgradeForm";
import { ConvertedLicense } from "../upgrade/ConvertedLicense";
import { NotEligibleLicense } from "../upgrade/NotEligibleLicense";
import userImg from "../../../img/upgrade/user.svg";
import crossImg from "../../../img/upgrade/cross.svg";
import { UpgradeContactForm } from "../upgrade/UpgradeContactForm";
import {LicenseUpgradeService} from "../../utils/Services";

type ViewState = "form" | "notEligible" | "converted";

interface UpgradeContactModalProps {
    isOpen: boolean;
    onClose: () => void;
    license: string;
    onSuccess: (email: string) => void;
    onError: () => void
}

const UpgradeContactModal = (props: UpgradeContactModalProps) => {
    const { isOpen, onClose, onSuccess, onError, license } = props;
    
    const Backdrop = () => <div
            onClick={onClose}
            className="position-absolute top-0 start-0 w-100 h-100"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        />

    return (
        isOpen && (
            <>
                <Backdrop/>
                <div className="d-flex flex-column gap-3 position-absolute top-50 start-50 translate-middle d-inline-block" style={{zIndex: 100}}>
                    <div className="p-4 card bg-light text-dark">
                        <div className="hstack gap-4 align-items-center">
                            <img alt="" className="icon-size" src={userImg} />
                            <div>
                                If you wish us to <strong>contact you using a different email</strong>,{" "}
                                <span className="nobr">please enter it here.</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 card bg-light text-dark">
                        <button
                            onClick={onClose}
                            className="position-absolute top-0 end-0 mt-1 me-1 bg-transparent border-0"
                        >
                            <img alt="" src={crossImg} className="image-size" />
                        </button>
                        <UpgradeContactForm
                            onError={onError}
                            onSuccess={onSuccess}
                            wasEligible={false}
                            license={license}
                        />
                    </div>
                </div>
            </>
        )
    );
};

function useAlternativeStyles() {
    useEffect(() => {
        const mainStyle = document.getElementById("main_styles");
        const isProduction = !!mainStyle;
        if (isProduction) {
            mainStyle.parentNode.removeChild(mainStyle);

            const newStyle = document.createElement("link");
            newStyle.setAttribute("rel", "stylesheet");
            newStyle.setAttribute("href", "/dist/styles/upgrade.css");
            document.head.appendChild(newStyle);
        } else {
            const styles = Array.from(document.querySelectorAll("style"));
            styles.forEach((style) => {
                style.parentNode.removeChild(style);
            });

            const newStyle = document.createElement("script");
            newStyle.setAttribute("src", "/dev/js/upgrade.js");
            document.head.appendChild(newStyle);
        }
    }, []);
}

const licenseUpgradeService = new LicenseUpgradeService();

export function UpgradeLicensePage() {
    const [viewState, setViewState] = useState<ViewState>("form");
    const [error, setError] = useState<string>("");
    const [license, setLicense] = useState<string>("");
    const [targetEmail, setTargetEmail] = useState<string>(null);
    const [didEmailChange, setDidEmailChange] = useState<boolean>(false);
    const [isUpgradeContactErrored, setIsUpgradeContactErrored] = useState(false);
    const [isUpgradeContactModalOpen, setIsUpgradeContactModalOpen] = useState<boolean>(false);

    useAlternativeStyles();

    const onLicenseConverted = (data: UpgradeLicenseResponse) => {
        setTargetEmail(data.email);
        setViewState("converted");
    };

    const onLicenseNotEligible = (data: UpgradeLicenseResponse) => {
        setTargetEmail(data.email);
        setViewState("notEligible");
    };

    const onUpgradeContactModalClose = () => {
        setIsUpgradeContactModalOpen(false);
    };

    const onUpgradeContactModalSuccess = (email: string) => {
        setDidEmailChange(true);
        setTargetEmail(email);
        setIsUpgradeContactModalOpen(false);
        setIsUpgradeContactErrored(false);
    };

    const onUpgradeContactModalError = () => {
        setIsUpgradeContactErrored(true)
        setIsUpgradeContactModalOpen(false)
    };

    return (
        <>
            <TopBar />
            <UpgradeLicenseWindow>
                
                <UpgradeContactModal
                    license={license}
                    isOpen={isUpgradeContactModalOpen}
                    onClose={onUpgradeContactModalClose}
                    onSuccess={onUpgradeContactModalSuccess}
                    onError={onUpgradeContactModalError}
                ></UpgradeContactModal>
                
                {error && <div className="alert bg-danger w-100 text-center active">{error}</div>}
                
                {viewState === "converted" && (
                    <ConvertedLicense
                        openUpdateContactModal={() => setIsUpgradeContactModalOpen(true)}
                        email={targetEmail}
                    />
                )}
                
                {viewState === "notEligible" && (
                    <NotEligibleLicense
                        isUpdateContactErrored={isUpgradeContactErrored}
                        didEmailChange={didEmailChange}
                        licenseUpgradeService={licenseUpgradeService}
                        openUpdateContactModal={() => setIsUpgradeContactModalOpen(true)}
                        email={targetEmail}
                    />
                )}
                
                {viewState === "form" && (
                    <UpgradeForm
                        license={license}
                        setLicense={setLicense}
                        onError={setError}
                        onConverted={onLicenseConverted}
                        onNotEligible={onLicenseNotEligible}
                        licenseUpgradeService={licenseUpgradeService}
                    />
                )}
                
            </UpgradeLicenseWindow>
        </>
    );
}
