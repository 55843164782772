import { deepCopy } from "../../utils/misc";
import { ErrorState } from "./ErrorState";
import { LoginState } from "./LoginState";
import { MappingState } from "./MappingState";
import { LicenseDetailsState } from "./LicenseDetailsState"
import { RouterState } from "connected-react-router";

export interface AppState {
    router: RouterState,
    errorState: ErrorState;
    login: LoginState;
    mappingState: MappingState;
    licenseInfo: LicenseDetailsState;
}

export function modifyState<S>(state: S, modify: (state: S) => void): S {
    const stateCopy = deepCopy(state);
    modify(stateCopy);
    return stateCopy;
}
