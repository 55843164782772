import { LoginAction } from "../actions/loginAction";
import { LoginState } from "../state/LoginState";
import { modifyState } from "../state";
import * as actionTypes from '../actions/actionTypes';

const initialState: LoginState = {
    loadingLogin: false,
    token: '',
    error: null
};

const loginStart = (state: LoginState, action) => {
    return modifyState(state, (s: LoginState) => {
        s.loadingLogin = true;
        s.error = null;
    });
}

const loginSuccess = (state: LoginState, action: string): LoginState => {
    return modifyState(state, s => {
        s.token = action;
        s.loadingLogin = false;
        s.error = null;
    });
}

const loginFail = (state: LoginState) => {
    return modifyState(state, (s: LoginState) => {  
        s.loadingLogin = false;
    });
}

const logout = (state: LoginState, action): LoginState => {
    return modifyState(state, (s: LoginState) => s.token = '');
}

export function loginReducer(state: LoginState = initialState, action: LoginAction): LoginState {
    switch (action.type) {
        case actionTypes.LOGIN_START:
            return loginStart(state, action);
        case actionTypes.LOGIN_SUCCESS:
            return loginSuccess(state, action.loginData);
        case actionTypes.LOGIN_FAIL:
            return loginFail(state);
        case actionTypes.LOGOUT:
            return logout(state, action);
        default: return state;
    }
}
