import React from 'react';
import NavigationItem from './NavigationItem';

const NavigationItems = () => {
    return <ul>
        <NavigationItem link="/licenses" ico="icon-my-licenses" fullAccess >My License</NavigationItem>
        <NavigationItem link="/dns" ico="icon-dns-management" fullAccess >DNS Management</NavigationItem>
        {/* <NavigationItem link="/" ico="icon-payments"fullAccess={props.fullAccess} >Payments</NavigationItem>
                    <NavigationItem link="/"  ico="icon-support" fullAccess = {props.fullAccess}>Support</NavigationItem>
                    <NavigationItem link="/"  ico="icon-license-emails" fullAccess = {props.fullAccess}>License e-mails</NavigationItem>*/}
        <a className="download" onClick={() => window.open('https://ravendb.net/download')}> <i className={"icon-raven"} ></i>Download RavenDB</a>
    </ul>
}

export default NavigationItems;

