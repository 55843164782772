import React from 'react';
import NavigationItems from './NavigationItems';

const SideBar = () => <div className="sidebar">
        <nav className="main-menu">
            <NavigationItems />
        </nav>
    </div>;

export default SideBar;
