import { MappingState } from '../state/MappingState';
import { FetchMappingAction } from '../actions/fetchMappingAction';
import * as actionTypes from '../actions/actionTypes';
import { modifyState } from '../state';
import { NodeStatus } from '../../models/UserNodes/NodeStatus';
import UserNodes from '../../models/UserNodes';
import { UserDomain } from '../../models/UserNodes/UserNodesMappings';

const initialState: MappingState = {
    userNodesData: null,
    loadingMapping: false
}

const fetchMappingStart = (state: MappingState, action): MappingState => {
    return modifyState(state, s => {
        s.loadingMapping = true
    });
}

function normalizeUserNodesData(userNodesData: UserNodes) {
    if (!userNodesData) {
        return userNodesData;
    }

    if (!userNodesData.mappings) {
        return userNodesData;
    }

    for (const node of userNodesData.mappings) {
        normalizeUserDomainData(node);
    }

    return userNodesData;
}

function normalizeUserDomainData(node: UserDomain) {
    if (!node.domainsData) {
        return;
    }

    for (const domain of node.domainsData) {
        domain.ips = domain.ips || [];
    }

    return node;
}

const fetchMappingSuccess = (state: MappingState, userNodesData: UserNodes, domainIndex: number): MappingState => {
    if (domainIndex === null) {
        return modifyState(state, s => {
            s.userNodesData = normalizeUserNodesData(userNodesData);
        });
    } 
    
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex] = 
            normalizeUserDomainData(userNodesData.mappings[domainIndex]);
    });
    
}

const fetchMappingFail = (state: MappingState, action): MappingState => {
    return modifyState(state, s => {
        s.loadingMapping = false;
    });
}

const saveChangesDNSStart = (state: MappingState): MappingState => {
    return modifyState(state, s => {
        s.loadingMapping = true
    });
}

const saveChangesDNSSuccess = (state: MappingState): MappingState => {
    return modifyState(state, s => {
        s.loadingMapping = false
    });
}

const saveChangesDNSFail = (state: MappingState): MappingState => {
    return modifyState(state, s => {
        s.loadingMapping = false
    });
}

const removeNode = (state: MappingState, domainIndex: number, nodeIndex: number): MappingState => {
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].status = "Trash";
    });
}

const updateNewNode = (state: MappingState, domainIndex: number, nodeIndex: number, fullDomainName: string, ips: string[]): MappingState => {
    const newState = modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].fullNodeDomainName = fullDomainName;
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].ips = ips;
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].status = "Created";
    });
    return newState;

}

const addNode = (state: MappingState, domainIndex: number) => {
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData.push({
            fullNodeDomainName: '',
            status: "Create",
            ips: ['0.0.0.0'],
            updatedAt: null
        });
    });
}

const unDoRemoveNode = (state: MappingState, domainIndex: number, nodeIndex: number): MappingState => {
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].status = null;
    });
}

const unDoNewNode = (state: MappingState, domainIndex: number, nodeIndex: number): MappingState => {
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData.splice(nodeIndex, 1)
    });
}


const chageNodeIp = (state: MappingState, domainIndex: number, nodeIndex: number, ips: string[]) => {
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].ips = ips;
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].status = "Edited";
    })
}

const chageNodeStatus = (state: MappingState, domainIndex: number, nodeIndex: number, status: NodeStatus) => {
    return modifyState(state, s => {
        s.userNodesData.mappings[domainIndex].domainsData[nodeIndex].status = status;
    })
}

const sortNodeStateAlphb = (state: MappingState) => {
    return modifyState(state, s => {
        if (s.userNodesData &&
            s.userNodesData.mappings) {
            s.userNodesData.mappings.forEach(domain => {
                domain.domainsData.sort((a, b) => {
                    return a.fullNodeDomainName.localeCompare(b.fullNodeDomainName)
                });
            });
        }
    });
}

const leaveDnsManagementPage = (state: MappingState) => {
    return modifyState(state, s => {
        s.userNodesData = null
    })
}

export function mappingReducer(state: MappingState = initialState, action: FetchMappingAction): MappingState {
    switch (action.type) {
        case actionTypes.FETCH_MAPPING_START:
            return fetchMappingStart(state, action);
        case actionTypes.FETCH_MAPPING_SUCCESS:
            return fetchMappingSuccess(state, action.UserNodesData, action.domainIndex);
        case actionTypes.FETCH_MAPPING_FAIL:
            return fetchMappingFail(state, action);
        case actionTypes.SAVE_CHANGES_DNS_START:
            return saveChangesDNSStart(state);
        case actionTypes.SAVE_CHANGES_DNS_START:
            return saveChangesDNSSuccess(state);
        case actionTypes.SAVE_CHANGES_DNS_START:
            return saveChangesDNSFail(state);
        case actionTypes.REMOVE_NODE:
            return removeNode(state, action.domainIndex, action.nodeIndex);
        case actionTypes.ADD_NODE:
            return addNode(state, action.domainIndex);
        case actionTypes.CHANGE_IP_NODE:
            return chageNodeIp(state, action.domainIndex, action.nodeIndex, action.ips);
        case actionTypes.CHANGE_NODE_STATUS:
            return chageNodeStatus(state, action.domainIndex, action.nodeIndex, action.status);
        case actionTypes.SAVE_NEW_NODE:
            return updateNewNode(state, action.domainIndex, action.nodeIndex, action.fullDomainName, action.ips);
        case actionTypes.UNDO_REMOVE_NODE:
            return unDoRemoveNode(state, action.domainIndex, action.nodeIndex);
        case actionTypes.UNDO_NEW_NODE:
            return unDoNewNode(state, action.domainIndex, action.nodeIndex);
        case actionTypes.SORT_ALPHABET:
            return sortNodeStateAlphb(state);
        case actionTypes.LEAVE_DNS_MANAGEMENT_PAGE:
            return leaveDnsManagementPage(state);
        default:
            return state;
    }
}
