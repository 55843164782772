import { combineReducers } from "redux";
import { errorReducer } from "./errorReducer";
import { AppState } from "../state";
import { loginReducer } from "./loginReducer";
import { mappingReducer } from "./mappingReducer";
import  licenseDetailsReducer  from './licenseDetailsReducer';
import { connectRouter } from 'connected-react-router';

export default function createRootReducer(history) { 
    return combineReducers<AppState>({
        router: connectRouter(history),
        errorState: errorReducer,
        login: loginReducer,
        mappingState: mappingReducer,
        licenseInfo: licenseDetailsReducer
    });
}
