import { RefObject } from 'react'
export default function FancyBackground(canvasRef: RefObject<HTMLCanvasElement>, stopPaint: boolean) {

    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');
    let mousex = undefined;
    let mousey = undefined;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;


    const circlesCount = 30;
    const maxRadius = 40;
    const circleArray = [];
    const distanceFromMouse = 100;
    const minOpacity = .005;
    const maxOpacity = .5;
    const colorArray = [
        '215, 50, 91',
        '193, 193, 194',
        '122, 121, 121',
        '70, 69, 69'
    ]

    const Circle = function (x, y, dx, dy, radius) {
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.radius = radius;
        this.minRadius = radius;
        this.color = 'rgba(' + colorArray[Math.floor(Math.random() * colorArray.length)] + ',' + (Math.random() * (maxOpacity - minOpacity) + minOpacity) + ')';

        this.draw = function () {
            c.beginPath();
            c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);



            c.fillStyle = this.color;
            c.fill();
        }
        this.update = function () {
            if (this.y - this.radius < 0) {
                this.y = innerHeight;
            } else {
                this.y += this.dy;
            }
            if (mousex - this.x < distanceFromMouse)
                this.x += this.dx
            if (mousex - this.x > -distanceFromMouse)
                this.x -= this.dx

            this.draw();
        }
    }
    const init = () => {
        circleArray.length = 0;
        for (let i = 0; i < circlesCount; i++) {
            const radius = Math.random() * 4 + 1;
            const x = Math.random() * (innerWidth - radius * 2) + radius;
            const y = Math.random() * (innerHeight - radius * 2) + radius;
            const dx = (Math.random() - 0.5) * 1;
            const dy = (Math.random() - 1) * 0.4;
            circleArray.push(new Circle(x, y, dx, dy, radius))
        }
    }

    const debounce = (func) => {
        let timer
        return (event) => {
            if (timer) { clearTimeout(timer) }
            timer = setTimeout(func, 100, event)
        }
    }

    const animate = () => {
        requestAnimationFrame(animate);
        c.clearRect(0, 0, innerWidth, innerHeight);

        for (let i = 0; i < circleArray.length; i++) {
            circleArray[i].update();
        }
    }
 
    init();
    animate();
    if (stopPaint === false) {
        window.addEventListener('mousemove', (e) => {
            mousex = e.x
            mousey = e.y
        });
        window.addEventListener('resize', debounce(() => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            init();
        }));
    } else {
        window.removeEventListener('mousemove', (e) => {
            mousex = e.x
            mousey = e.y
        });
    }
}
