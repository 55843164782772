import React, { ReactNode } from 'react';

interface Props {
    balloonText?: string;
    children: ReactNode;
    big?: boolean;
}

const TextWithBalloon = (props: Props) => {
    return <div className="tooltip" >
        {props.children}
        <span className="tooltiptext">{props.balloonText}</span>
    </div>
}

export default TextWithBalloon;