import React from 'react';
import Button from '../../components/ui/Button';
import { SaveChangesStatus } from '../../models/SaveChangesStatus';
interface Props {
    rootDomain: string;
    clickAbleSaveButton: boolean;
    saveChanges: () => void;
    addNewNode: () => void;
    saveButtonStatus: SaveChangesStatus;
}

const EditDomainPanelHeader = (props: Props) => {
    let buttonclass = ''

    switch (props.saveButtonStatus) {
        case "Saving":
            buttonclass = 'loading';
            break;
        case "Success":
            buttonclass = 'loading done';
            break;
        case "Error":
            buttonclass = 'loading error';
            break;
        default:
            buttonclass = '';
    }
    
    return <div className="padding center flex-horizontal">
        <div className="properties flex-grow">
            <div className="properties-item-icon">
                <div className="icons-margin">
                    <i className="icon-dns-management"></i>
                </div>
                <div className="property-item">
                    <div className="name">
                        Domain
                            </div>
                    <div className="value text-lighter">
                        {props.rootDomain}
                    </div>
                </div>
            </div>
        </div>
        <div className="btn-group">
            <Button classes="btn" ico="icon-plus" onClick={props.addNewNode}>
                Add Node
            </Button>
            {/* <Button classes="btn btn-primary animation " ico="icon-save" disabled={props.clickAbleSaveButton} onClick={props.saveChanges}>
                            Save Changes
                    </Button> */}
            {!props.clickAbleSaveButton && <button disabled={props.clickAbleSaveButton} onClick={props.saveButtonStatus === "Available" ? props.saveChanges : null} className={["btn animation saveChangesButton ", buttonclass].join(" ")}>
                <span>
                    <i className="icon-save"></i>
                    <i className="icon-refresh"></i>
                    <i className="icon-accept"></i>
                    <i className="icon-cancel"></i>
                </span>
                <ul>
                    <li style={{ position: "absolute", left: 0 }}>Save Changes</li>
                    <li>Saving</li>
                    <li>Success</li>
                    <li>Error</li>
                </ul>
            </button>}
        </div>
    </div>
}

export default EditDomainPanelHeader;
