import { createStore, applyMiddleware, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "./state";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { Action } from "./actions";
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { createBrowserHistory } from "history";

// TODO remove devtools on prod
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

export function configureStore(preloadedState?) {
    const store = createStore<AppState, Action, any, any>(
        createRootReducer(history),
        preloadedState,
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            thunk as ThunkMiddleware<AppState, Action>)));
    return store;
}

export type CustomersThunkDispatch = ThunkDispatch<AppState, null, Action>;
export type GetState = () => AppState;
