import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoginState } from '../../store/state/LoginState';
import { Spinner } from '../ui/Spinner';
import { AppState } from '../../store/state';
import { LicenseDetailsState } from '../../store/state/LicenseDetailsState';
import LicensePanelHeader from '../license/LicensePanelHeader'
import { CustomersThunkDispatch } from '../../store';
import * as actions from '../../store/actions/';
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'connected-react-router';


interface StateProps {
    loginState: LoginState;
    licenseInfo: LicenseDetailsState
}

interface DispatchProps {
    openLicenseDetails: (index: number) => void;
    fetchLicenseDetails: () => void;
}

class LicenseListPageComponent extends PureComponent<StateProps & DispatchProps & RouteComponentProps, {}>{

    componentDidMount() {
        if (!this.props.licenseInfo.loadingLicenseDetails &&
            !this.props.licenseInfo.licenses) {
            this.props.fetchLicenseDetails();
        }
    }

    expandLicenseHandler = (index: number) => {
        this.props.openLicenseDetails(index);
    }

    render() {
        const { openLicenseDetails } = this.props;
        if (this.props.licenseInfo.licenses) {
            const { softwareProducts } = this.props.licenseInfo.licenses;
            return <div className="body">
                <h2>My Licenses</h2>
                {softwareProducts.map((el, index) =>
                    <LicensePanelHeader
                        key={el.fullProductName}
                        productName={el.fullProductName}
                        alias="Production"
                        index={index}
                        expandLicenseIndex={() => openLicenseDetails(index)}
                        PropertyItems={[
                            ...el.properties.filter(el => el.name === "version"),
                            { name: "expires", value: el.expiration }
                        ]} />)}
            </div>;
        }

        return <Spinner></Spinner>

    }
}
const mapStateToProps = ({ login, licenseInfo }: AppState) => {
    return {
        loginState: login,
        licenseInfo
    };
};
const mapDispatchToProps = (dispatch: CustomersThunkDispatch): DispatchProps => {
    return {
        openLicenseDetails: (index: number) => {
            // (document as any).location = "/licenses/" + index;
            return dispatch(push('/licenses/' + index));
        },
        fetchLicenseDetails: () => dispatch(actions.fetchLicenseDetails())
    };
};

const LicenseListPage = connect<StateProps, DispatchProps, {}>(
    mapStateToProps, mapDispatchToProps)(LicenseListPageComponent);
export default LicenseListPage;
