import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux';
import { SoftwareProduct } from '../../models/LicenseListModel';
import Header from '../license/ProductNameHeader';
import Button from '../ui/Button';
import { AppState } from '../../store/state';
import LicensePropertyComponent from '../license/LicenseProperty';
import { fetchLicenseDetails } from '../../store/actions';
import { CustomersThunkDispatch } from '../../store';
import { Spinner } from '../ui/Spinner';
import { matchPath } from "react-router-dom";
import { push } from 'connected-react-router';

interface StateProps {
    softwareProductLicense: SoftwareProduct
}

interface DispatchProps {
    fetchLicenseDetails: () => void;
    openLicenseList: () => void;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class LicenseDetailsPageComponent extends Component<Props, {}> {
    
    componentDidMount() {
        if (!this.props.softwareProductLicense) {
            this.props.fetchLicenseDetails();
        }
    }

    render() {
        if (!this.props.softwareProductLicense) {
            return <Spinner></Spinner>;
        }
        
        const { softwareProductLicense } = this.props;
        const licenseProperties = softwareProductLicense.properties.reduce((result, next) => {
            return { 
                ...result,
                [next.name]: next.value
            };
        }, {});
        const versionAndExpiration = [
            { name: "version", value: licenseProperties["version"] },
            { name: "expires", value: this.props.softwareProductLicense.expiration }
        ];

        return <div className="body">
            <div className="flex-horizontal margin-bottom flex-wrap flex-noshrink">
                <Header ico="icon-license" context={softwareProductLicense.fullProductName} spanClass="alias" spanContext="Production" />
                <div className="actions">
                    <div className="btn-group">
                        <Button classes="btn btn-sm btn-primary" 
                            onClick={this.props.openLicenseList} > Close Details</Button>
                        {/* <Button classes="btn btn-sm btn-default" ico="icon-upgrade"> Upgrade</Button>
                    <Button classes="btn btn-sm btn-default" ico="icon-support"> Buy Support</Button>
                    <Button classes="btn btn-sm btn-default" ico="icon-invoices"> Invoices</Button> */}
                    </div>
                </div>
            </div>
            <div className="flex-horizontal">
                <div className="margin-right flex-grow flex-vertical">
                    <div className="panel license-item flex-noshrink">
                        <div className="padding">
                            <div className="properties">
                                <div className="icon-margin"></div>

                                {versionAndExpiration.map(el => <LicensePropertyComponent key={el.name} item={el} />)}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="license-specs-container panel padding">
                    <div className="license-specs">
                        <div className="name">Eligible for Commercial Use</div>
                        <div className="value"><i className={licenseProperties["type"] === "Developer" ? "icon-cancel" : "icon-checkmark"}></i></div>
                        <div className="name">Number of databases</div>
                        <div className="value"><i className="icon-infinity"></i></div>
                        <div className="name">Single database size</div>
                        <div className="value"><i className="icon-infinity"></i></div>
                        <div className="name">Management Studio (GUI)</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <h4>Clustering</h4>
                        <div className="name">Max cluster size</div>
                        <div className="value"><strong>{licenseProperties["maxClusterSize"]}</strong></div>
                        <div className="name">Max cores in cluster</div>
                        <div className="value"><strong>{licenseProperties["cores"]}</strong></div>
                        <div className="name">Max cluster memory usage</div>
                        <div className="value"><strong>{licenseProperties["memory"]}GB RAM</strong></div>
                        <div className="name">Highly available tasks</div>
                        <div className="value"><i className={licenseProperties["highlyAvailableTasks"] ? "icon-checkmark" : "-"}></i></div>
                        <div className="name">Dynamic database distribution</div>
                        <div className="value"><i className={licenseProperties["dynamicNodesDistribution"] ? "icon-checkmark" : "-"}></i></div>
                        <h4>Monitoring</h4>
                        <div className="name">Server Dashboard</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <div className="name">SNMP</div>
                        <div className="value"><i className={licenseProperties["snmp"] ? "icon-checkmark" : "-"}></i></div>
                        <h4>Extensions</h4>
                        <div className="name">Document Revisions</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <div className="name">Document Expiration</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <div className="name">Attachments</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <h4>External Replication</h4>
                        <div className="name">Immediate</div>
                        <div className="value"><i className={licenseProperties["externalReplication"] ? "icon-checkmark" : "-"}></i></div>
                        <div className="name">Delayed</div>
                        <div className="value"><i className={licenseProperties["delayedExternalReplication"] ? "icon-checkmark" : "-"}></i></div>
                        <h4>Backups</h4>
                        <div className="name">Local</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <div className="name">Cloud & Remote</div>
                        <div className="value"><i className={licenseProperties["cloudBackup"] ? "icon-checkmark" : "-"}></i></div>
                        <div className="name">Snapshot backups</div>
                        <div className="value"><i className={licenseProperties["snapshotBackup"] ? "icon-checkmark" : "-"}></i></div>
                        <h4>ETL</h4>
                        <div className="name">SQL ETL</div>
                        <div className="value"><i className={licenseProperties["sqlEtl"] ? "icon-checkmark" : "-"}></i></div>
                        <div className="name">RavenDB ETL</div>
                        <div className="value"><i className={licenseProperties["ravenEtl"] ? "icon-checkmark" : "-"}></i></div>
                        <div className="name">Certificates</div>
                        <div className="value"><i className="icon-checkmark"></i></div>
                        <div className="name">Encryption in transit</div>
                        <div className="value"><strong>TLS 1.2 & X.509</strong></div>
                        <div className="name">Storage encryption</div>
                        <div className="value"><i className={licenseProperties["encryption"] ? "icon-checkmark" : "-"}></i></div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const EMPTY = { softwareProductLicense: null };
const mapStateToProps = ({ licenseInfo, router }: AppState, ) => {
    if (!licenseInfo.licenses) {
        return EMPTY;
    }

    const path = router.location.pathname;
    const match = path && matchPath(path, { path: "/licenses/:id" });
    if (!match) {
        return EMPTY;
    }

    const softwareProductLicense = licenseInfo.licenses.softwareProducts[match.params["id"]];
    return { softwareProductLicense };
}

const mapDispatchToProps = (dispatch: CustomersThunkDispatch): DispatchProps => {
    return {
        fetchLicenseDetails: () => dispatch(fetchLicenseDetails()),
        openLicenseList: () => dispatch(push("/licenses"))
    }
};

const LicenseDetailsPage = connect<StateProps, DispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps)(LicenseDetailsPageComponent)
export default LicenseDetailsPage;
