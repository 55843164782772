import React from 'react';

const WarningMessage = () => {
    return <div className="row flex-noshrink margin-bottom">
        <div className="bg-primary center padding padding-sm flex-horizontal col-md">
            <i className="icon-warning margin-right text-lg flex-noshrink"></i>
            <div>
                <p className="removeMargin">WARNING:  These settings define the routings to your domains.</p>
                <p className="removeMargin"> Modifying these setting incorrectly may cause your domains to become inaccessible.</p>
            </div>
        </div>
        <div className="panel padding padding-sm flex-horizontal center col-sm">
            <i className="icon-clock text-lg margin-right flex-noshrink"></i>
            <div>Changes may take 30 minutes to an hour</div>
        </div>
    </div>

}
export default WarningMessage;