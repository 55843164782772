import React, { ReactNode } from 'react';

interface Props {
    classes?: string;
    ico?: string;
    onClick?: () => void;
    children: ReactNode;
    disabled?: boolean
}

const Button = (props: Props) => {
    return <button className={props.classes} onClick={props.onClick} disabled={props.disabled}>
                <i className={props.ico}></i>
                {props.children}
           </button>
}

export default Button;