import React, { Component } from 'react';
import TextWithBalloon from '../../components/ui/TextWithBalloon';
import moment = require('moment');
import { NodeStatus } from '../../models/UserNodes/NodeStatus';
import { UserNode } from '../../models/UserNodes/UserNode';
import Button from '../../components/ui/Button';
import { CustomersThunkDispatch } from '../../store';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { timeFromNow } from '../../utils/misc';
interface DispatchProps {
    onNodeRemoved: (domainIndex: number, nodeIndex: number) => void;
    onUpdateNodesIp: (nodeIndex: number, domainIndex: number, ips: string[]) => void;
    onUndoRemoveNodes: (domainIndex: number, nodeIndex: number) => void;
    onChangeStatus: (domainIndex: number, nodeIndex: number, status: NodeStatus) => void;
    onUpdateNewNode: (nodeIndex: number, domainIndex: number, fullDomainName: string, ips: string[]) => void;
    onUnDoNewNode: (domainIndex: number, nodeIndex: number) => void;

}

interface Props {
    node: UserNode;
    nodeIndex: number;
    domainIndex: number;
    subDomain: string;
    onDeleteClick: (fullDomainName: string) => void;
    changeIpClicked: (fullDomainName: string, ips: string[]) => void;
    unDoDeleteClicked: (fullDomainName: string) => void;
    onRemoveFromUpdate: (fullDomainName: string) => void;
    checkDuplicatNodes: (fullDomainName: string, nodeIndex: number) => boolean;
    isUniqueIpAcrossNodes: (fullDomainName: string, ips: string[]) => boolean;
}

interface NodesState {
    whileTypingIp: string;
    whileTypingHostName: string;
    originIp: string;
}

function splitMultipleIpsString(ipsString) {
    return ipsString.split(/[\s,]+/);
}


class NodeListComponent extends Component<Props & DispatchProps, NodesState>{
    private inputElement: React.RefObject<HTMLInputElement>;
    
    state: NodesState = {
        whileTypingIp: '',
        whileTypingHostName: '',
        originIp: ''
    }

    constructor(props) {
        super(props);
        this.inputElement = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.node.status === "EditMode") {
            this.inputElement.current.focus();
        }
    }

    componentDidMount() {
        if (this.props.node.status === "Create")
            this.inputElement.current.focus();
    }

    onTypeIpHandler = (event) => {
        this.setState({ whileTypingIp: event.target.value });
    }

    onTypeNodeNameHandler = (event) => {
        this.setState({ whileTypingHostName: event.target.value });
    }

    validationOfNodeName = (newNodeName: string): boolean => {
        return /^[A-Za-z]{1,4}$/.test(newNodeName);
    }
    
    rightButtonHandler = () => {
        switch (this.props.node.status) {
            case "EditMode":
                this.props.onChangeStatus(this.props.domainIndex, this.props.nodeIndex, null);
                break;
            case "Trash":
                this.props.onUndoRemoveNodes(this.props.domainIndex, this.props.nodeIndex);
                this.props.unDoDeleteClicked(this.props.node.fullNodeDomainName);
                break;
            case "Create":
                this.props.onUnDoNewNode(this.props.domainIndex, this.props.nodeIndex);
                break;
            case "Edited":
                this.setState({ whileTypingIp: this.state.originIp });
                this.props.onUpdateNodesIp(this.props.domainIndex, this.props.nodeIndex, this.state.originIp.split(/[\s,]+/));
                this.props.onChangeStatus(this.props.domainIndex, this.props.nodeIndex, null);
                this.props.onRemoveFromUpdate(this.props.node.fullNodeDomainName);
                break;
            case "Created":
                this.props.onUnDoNewNode(this.props.domainIndex, this.props.nodeIndex);
                this.props.onRemoveFromUpdate(this.props.node.fullNodeDomainName);
                break;
            default:
                this.props.onNodeRemoved(this.props.domainIndex, this.props.nodeIndex);
                this.props.onDeleteClick(this.props.node.fullNodeDomainName);
        }
    }

    leftButtonHandler = () => {
        const getEditedIps = () => splitMultipleIpsString(this.state.whileTypingIp);
        const isEditedIpValid = () => this.validateMultipleIpsString(this.state.whileTypingIp);
        const nodeFullName = () => this.props.node.fullNodeDomainName;
        const domainIndex = () => this.props.domainIndex;
        const nodeIndex = () => this.props.nodeIndex;
        const hostNameWithSubDomain = () => this.state.whileTypingHostName + this.props.subDomain;

        switch (this.props.node.status) {
            case "EditMode":
                if (!isEditedIpValid
                    || !this.props.isUniqueIpAcrossNodes(nodeFullName(), getEditedIps()))
                    break;
                this.props.onUpdateNodesIp(domainIndex(), nodeIndex(), getEditedIps());
                this.props.changeIpClicked(nodeFullName(), getEditedIps());
                break;
            case "Create":
                if (!this.validateMultipleIpsString(this.state.whileTypingIp)
                    || !this.props.checkDuplicatNodes(hostNameWithSubDomain(), nodeIndex())
                    || !this.validationOfNodeName(this.state.whileTypingHostName)
                    || !this.props.isUniqueIpAcrossNodes(nodeFullName(), getEditedIps()))
                    break;
                this.props.onUpdateNewNode(
                    domainIndex(), nodeIndex(), hostNameWithSubDomain(), getEditedIps());
                this.props.changeIpClicked(hostNameWithSubDomain(), getEditedIps());
                break;
            case "Created":
                this.props.onChangeStatus(domainIndex(), nodeIndex(), "Create");
                break;
            default:
                this.setState({ whileTypingIp: this.props.node.ips.join(' ') });
                this.props.onChangeStatus(domainIndex(), nodeIndex(), "EditMode");
                if (this.state.originIp === '') {
                    this.setState({ originIp: this.props.node.ips.join(' ') });
                }
        }
    }

    render() {
        let rightButton;
        let leftButton;
        let nodeDomain;
        let nodeIpclassName;
        let nodeIp;
        let rowClassName;
        let timePassedFromLastUpdateText = timeFromNow(this.props.node.updatedAt);
        switch (this.props.node.status) {
            case "Create":
                rowClassName = "row edit-mode";
                nodeDomain = (<input 
                    ref={this.inputElement} 
                    className="form-control" 
                    placeholder="Node name" 
                    value={this.state.whileTypingHostName} 
                    onChange={this.onTypeNodeNameHandler} />);
                nodeIpclassName = "node-ip markIP";
                nodeIp = <input 
                    value={this.state.whileTypingIp} 
                    className="form-control" 
                    placeholder="Node IP"
                    onChange={this.onTypeIpHandler} />
                rightButton = <Button onClick={this.rightButtonHandler} ico="icon-cancel"> </Button>
                leftButton = <Button onClick={this.leftButtonHandler} 
                    classes='text-success' ico="icon-accept"> </Button>
                timePassedFromLastUpdateText = ''
                break;
            case "Created":
                rowClassName = "row";
                nodeDomain = this.props.node.fullNodeDomainName
                    .split(this.props.subDomain)[0].toLocaleUpperCase() + this.props.subDomain;
                nodeIpclassName = "node-ip";
                nodeIp = <>
                    <i className="icon-wait" style={{ font: 'inherits' }}></i> 
                    {this.props.node.ips.join(",")}
                </>;
                rightButton = <Button onClick={this.rightButtonHandler} ico="icon-trash"> </Button>
                leftButton = <Button onClick={this.leftButtonHandler} ico="icon-edit"> </Button>
                timePassedFromLastUpdateText = ''
                break;
            case "EditMode":
                rowClassName = "row edit-mode";
                nodeDomain = this.props.node.fullNodeDomainName
                    .split(this.props.subDomain)[0].toLocaleUpperCase() + this.props.subDomain;
                nodeIpclassName = "node-ip markIP";
                nodeIp = <input 
                    value={this.state.whileTypingIp} 
                    ref={this.inputElement} 
                    className="form-control" 
                    onChange={this.onTypeIpHandler} />
                rightButton = <Button onClick={this.rightButtonHandler} ico="icon-cancel"> </Button>
                leftButton = <Button onClick={this.leftButtonHandler} 
                    classes='text-success' 
                    ico="icon-accept"> </Button>
                break;
            case "Edited":
                rowClassName = "row";
                nodeDomain = this.props.node.fullNodeDomainName
                    .split(this.props.subDomain)[0].toLocaleUpperCase() + this.props.subDomain;
                nodeIpclassName = "node-ip ";
                nodeIp = <> {this.props.node.ips.join(", ")} <i className="icon-wait" style={{ font: 'inherits' }}></i></>;
                rightButton = <Button onClick={this.rightButtonHandler} ico="icon-cancel"><small>Cancel Changes</small></Button>
                leftButton = <Button onClick={this.leftButtonHandler} ico="icon-edit"> </Button>
                break;
            case "Trash":
                rowClassName = "row disabled";
                nodeDomain = this.props.node.fullNodeDomainName
                    .split(this.props.subDomain)[0].toLocaleUpperCase() + this.props.subDomain;
                nodeIpclassName = "node-ip";
                nodeIp = this.props.node.ips.join(", ");
                rightButton = <Button onClick={this.rightButtonHandler} ico="icon-cancel"><small>Cancel Changes</small></Button>
                leftButton = null;
                break;
            default:
                rowClassName = "row";
                nodeDomain = this.props.node.fullNodeDomainName
                    .split(this.props.subDomain)[0].toLocaleUpperCase() + this.props.subDomain;
                nodeIpclassName = "node-ip";
                nodeIp = this.props.node.ips
                    .map((ip, i) => 
                        <p key={i} 
                           style={{ margin: 0, color: "inherit" }}>{ip}</p>);
                rightButton = <Button onClick={this.rightButtonHandler} ico="icon-trash"> </Button>
                leftButton = <Button onClick={this.leftButtonHandler} ico="icon-edit"> </Button>
                break;
        }

        return <div className={rowClassName}>
            <div className="node-domain">
                {nodeDomain}
            </div>
            <div className={nodeIpclassName}>
                {nodeIp}
            </div>
            <div className="node-domain">
                <TextWithBalloon 
                    balloonText={moment.utc(this.props.node.updatedAt)
                                       .format("YYYY MMMM Do  hh:mm A")}>
                    {timePassedFromLastUpdateText}
                </TextWithBalloon>
            </div>
            <div className="node-actions">
                {leftButton}
                {rightButton}

            </div>
        </div>
    }

    validateMultipleIpsString(str: string) {
        const ipArr = splitMultipleIpsString(str);
        const IPv6_REGEXP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
        const IPv4_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        let result = true;
        ipArr.forEach(ip => {
            if (IPv4_REGEXP.test(ip) === false 
                && IPv6_REGEXP.test(ip) === false)
                result = false;
        })
        return result;
    }
}

const mapDispatchToProps = (dispatch: CustomersThunkDispatch) => {
    return {
        onNodeRemoved: (domainIndex: number, nodeIndex: number) => 
            dispatch(actions.removeNode(domainIndex, nodeIndex)),
        onUpdateNodesIp: (domainIndex: number, nodeIndex: number, ips: string[]) => 
            dispatch(actions.chageNodeIp(domainIndex, nodeIndex, ips)),
        onUndoRemoveNodes: (domainIndex: number, nodeIndex: number) => 
            dispatch(actions.undoRemoveNode(domainIndex, nodeIndex)),
        onChangeStatus: (domainIndex: number, nodeIndex: number, status: NodeStatus) => 
            dispatch(actions.changeNodeStatus(domainIndex, nodeIndex, status)),
        onUpdateNewNode: (domainIndex: number, nodeIndex: number, fullDomainName: string, ips: string[]) => 
            dispatch(actions.updateNewNode(domainIndex, nodeIndex, fullDomainName, ips)),
        onUnDoNewNode: (domainIndex: number, nodeIndex: number) => 
            dispatch(actions.undoNewNode(domainIndex, nodeIndex))
    }

}
const NodeList = connect<{}, DispatchProps, {}>(null, mapDispatchToProps)(NodeListComponent);
export default NodeList;
