import React from 'react';

interface Props {
    ico?: string;
    context: string;
    spanClass?: string;
    spanContext?: string;
}

const Header = (props: Props) => {
    return <div className="product-name">
        <i className={props.ico}></i>
        <h1>
            {props.context}
            <span className={props.spanClass}>{props.spanContext}</span>
        </h1>
    </div>
}

export default Header;