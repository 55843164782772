import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store/state';
import { Dispatch } from 'redux';
import { hideErrorMessage } from '../../../store/actions/errorActions';

interface StateProps {
    errorMessage: string;
}

interface DispatchProps {
    hide: () => void;
}

type Props = StateProps & DispatchProps;

class ErrorModalComponent extends React.Component<Props> {
    componentDidUpdate(prevProps: Props) {
        const { errorMessage, hide } = this.props;
        if (!prevProps.errorMessage && !!errorMessage) {
            setTimeout(() => hide(), 6000);
        }
    }

    render() {
        const { errorMessage } = this.props;

        return <div className={ errorMessage ? 'ErrorModal active' : 'ErrorModal' }>
            {errorMessage}
        </div>
    }
}

function mapStateToProps(state: AppState): StateProps {
    const { errorState } = state;
    return {
        errorMessage: errorState && errorState.errorMessage
            ? errorState.errorMessage 
            : null
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        hide: () => dispatch(hideErrorMessage())
    };
}

const ErrorModal = connect<StateProps, DispatchProps, {}, AppState>(
    mapStateToProps, mapDispatchToProps)(ErrorModalComponent);
export default ErrorModal;
