import * as React from "react";
import { TopBar } from "./navigation/TopBar";
import SideBar from "./navigation/SideBar";
import ErrorModal from "./ui/modals/ErrorModal";

export default function withLayout(WrappedComponent: React.ComponentType) {
    return class WithLayoutComponent extends React.Component {
        render() {
            return <>
                <TopBar />
                <div className="container">
                    <SideBar />
                    <div className="body">
                        <ErrorModal />
                        <WrappedComponent />
                    </div>
                </div>
            </>;
        }
    };
}
