import React, { Component } from "react";
import { connect } from "react-redux";
import EditDomainPanel from "../dns/EditDomainPanel";
import { Prompt } from 'react-router';
import UserNodes from "../../models/UserNodes";
import { LoginState } from "../../store/state/LoginState";
import { Spinner } from "../../components/ui/Spinner";
import { UserDomain } from "../../models/UserNodes/UserNodesMappings";
import WarningMessage from "../../components/ui/WarningMessage";
import { AppState } from "../../store/state";
import { CustomersThunkDispatch } from "../../store";
import * as actions from '../../store/actions'

interface DispatchProps {
    onfetchMapping: (token: string, domainIndex: number) => void;
    onAddNewNode: (domainIndex: number) => void;
    leaveDnsManagementPage: () => void;
}

interface StateProps {
    loadingMapping: boolean;
    userNodesData: UserNodes;
    loginState: LoginState;
}
interface State {
    numberOfUnsavedBox: number;
    globalMask: number;
}
type Props = StateProps & DispatchProps;

class DnsManagementPageComponent extends Component<Props, State> {
    
    state = {
        numberOfUnsavedBox: 0,
        globalMask: 0,
        isBlocking: false
    }

    componentDidMount() {
        this.props.onfetchMapping(this.props.loginState.token, null);
    }

    componentWillUnmount() {
        this.props.leaveDnsManagementPage();
    }

    addNewNodeHandler = (domainIndex: number) => {
        this.props.onAddNewNode(domainIndex);
    }

    updateNumberOfUnsavedBoxHandler = (inc: boolean) => {
        this.setState({ numberOfUnsavedBox: inc ? this.state.numberOfUnsavedBox + 1 : this.state.numberOfUnsavedBox - 1 });
    }

    fetchMappingsForOneBoxHandler = (domainIndex: number) => {
        this.props.onfetchMapping(this.props.loginState.token, domainIndex);
    }

    isBlockingHandler = (mask: number, canLeaveThePage: boolean) => {
        let updatedState = this.state.globalMask;
        if (canLeaveThePage) {
            if ((updatedState & mask) !== 0)
                this.setState({ globalMask: updatedState ^ mask });
        }
        else
            this.setState({ globalMask: updatedState | mask });
    }

    render() {
        let mappingToDisplay; 
        if (this.props.userNodesData) {
            if (this.props.userNodesData.mappings) {
                mappingToDisplay = <>
                        {this.props.userNodesData.mappings.map((domain: UserDomain, index) => {
                            return <EditDomainPanel
                                key={domain.domainName}
                                domainToBuild={domain}
                                docId={this.props.userNodesData.id}
                                domainIndex={index}
                                updateNumberOfUnsavedBox={this.updateNumberOfUnsavedBoxHandler.bind(this)}
                                fetchMappingsForOneBox={this.fetchMappingsForOneBoxHandler.bind(this, index)}
                                addNewNode={this.addNewNodeHandler.bind(this)}
                                isBlocking={this.isBlockingHandler.bind(this)} />
                        })}
                    </>;
            } else {
                mappingToDisplay = <p>No domains registered for this license <b>{this.props.userNodesData.licenseID}</b>.</p>;
            }
        } else {
            mappingToDisplay = <Spinner></Spinner>;
        }

        return (
            <>
                <h2>DNS Management</h2>
                <WarningMessage />
                {mappingToDisplay}
                <Prompt
                    when={this.state.globalMask !== 0}
                    message='You have unsaved changes, are you sure you want to leave?' />
            </>
        );
    }
}

const mapStateToProps = ({ mappingState, login }: AppState) => {
    const { userNodesData } = mappingState;
    return {
        loadingMapping: mappingState.loadingMapping,
        userNodesData: userNodesData,
        loginState: login
    };
};

const mapDispatchToProps = (dispatch: CustomersThunkDispatch): DispatchProps => {
    return {
        onfetchMapping: (token: string, domainIndex: number) => dispatch(actions.fetchMapping(token, domainIndex)),
        onAddNewNode: (domainIndex: number) => dispatch(actions.addNode(domainIndex)),
        leaveDnsManagementPage: () => dispatch(actions.leaveDnsManagementPage())
    };
};

const DnsManagementPage = connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(DnsManagementPageComponent);
export default DnsManagementPage;
