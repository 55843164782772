import React from 'react';
import Button from '../ui/Button'
import LicensePropertyItem from './LicenseProperty'
import { LicenseProperty } from '../../models/LicenseListModel';

interface Props {
    productName: string;
    alias: string;
    PropertyItems: LicenseProperty[];
    index: number;
    expandLicenseIndex: () => void;
}

export default function LicensePanelHeader(props: Props) {
    return <div className="panel license-item">
            <div className="padding flex-horizontal center">
                <div className="product-name">
                    <i className="icon-license"></i>
                    <h1>{props.productName}
                        <span className="alias">{props.alias}</span>
                    </h1>
                </div>
                <div>
                    <Button classes="btn btn-default" ico="icon-edit" > Alias</Button>
                    <Button classes="btn btn-primary" onClick={props.expandLicenseIndex}> Details</Button>
                </div>
            </div>
            <div className="panel-addon">
                <div className="padding">
                    <div className="properties">
                        <div className="icons-margin"></div>
                        {props.PropertyItems.map(el => <LicensePropertyItem key={el.name} item={el} />)}

                    </div>
                </div>
            </div>
        </div>;
}
