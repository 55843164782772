import * as actionTypes from './actionTypes';
import { CustomersThunkAction } from "./index";
import { CustomersThunkDispatch } from "../index";
import { LoginDataModel } from '../../models/LoginDataModel';
import { LoginService } from '../../utils/Services';
import { showErrorMessage } from './errorActions';
import { push } from 'connected-react-router'

export const loginStart = (): loginStart => {
    return {
        type: actionTypes.LOGIN_START
    }
}

export const loginSuccess = (token: string): loginSuccess => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        loginData: token
    }
}

export const loginFail = (error: any): loginFail => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error
    }
}

export const logout = (): logout => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    return {
        type: actionTypes.LOGOUT
    }
}

const loginService = new LoginService();

export const login = (license: string): CustomersThunkAction => {
    return async (dispatch: CustomersThunkDispatch) => {
        dispatch(loginStart());
        try {
            const result: LoginDataModel = await loginService.login(license);
            const expirationDate = new Date(new Date().getTime() + result.expiredIn * 60 * 1000);
            localStorage.setItem('token', result.token);
            localStorage.setItem('expirationDate', expirationDate.toString());
            dispatch(loginSuccess(result.token));
            dispatch(checkLoginTimeout(result.expiredIn));
        } catch (error) {
            dispatch(loginFail(error));
            dispatch(showErrorMessage(error.response.data ? error.response.data.error : "Error occurred please try again later"));
        }
    }
}

export const logoutGotClicked = (): CustomersThunkAction => {
    return async (dispatch: CustomersThunkDispatch) => {
        dispatch(logout());
        try {
            const result = await loginService.logout();
            console.log(result);
            dispatch(push('/'));
        }
        catch (error) {
            dispatch(loginFail(error));
            dispatch(showErrorMessage(error));
        }
    }
}

const checkLoginTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 60 * 1000);
    }
}

export const logingCheckState = (): CustomersThunkAction => {
    return async (dispatch: CustomersThunkDispatch) => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = localStorage.getItem('expirationDate');
            if (new Date(expirationDate) > new Date()) {
                dispatch(loginSuccess(token));
                dispatch(checkLoginTimeout((new Date(expirationDate).getTime() - new Date().getTime()) / 60 / 1000));
            } else {
                dispatch(logout());
            }

        }
    }
}

export interface loginStart {
    type: actionTypes.LOGIN_START;
}

export interface loginSuccess {
    type: actionTypes.LOGIN_SUCCESS;
    loginData: string;
}

export interface loginFail {
    type: actionTypes.LOGIN_FAIL;
    error: any;
}

export interface logout {
    type: actionTypes.LOGOUT;
}

export type LoginAction = loginStart
    | loginSuccess | loginFail | logout;
