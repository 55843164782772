import React, { Component } from "react";
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions/index";
import { AppState } from "./store/state";
import { CustomersThunkDispatch } from "./store";
import withLayout from "./components/withLayout";
import { LoginPage } from "./components/pages/LoginPage";
import loginWithLayout from "./components/loginWithLayout";
import DnsManagementPage from "./components/pages/DnsManagementPage";
import LicenseListPage from "./components/pages/LicenseListPage";
import LicenseDetailsPage from "./components/pages/LicenseDetailsPage";
import { UpgradeLicensePage } from "./components/pages/UpgradeLicensePage";

function wrap(o) {
    return withLayout(o);
}
function wrapLogin(o) {
    return loginWithLayout(o);
}

const DnsManagement = wrap(DnsManagementPage);
const LicenseList = wrap(LicenseListPage);
const LicenseDetails = wrap(LicenseDetailsPage);
const UpgradeLicense = UpgradeLicensePage;
const Login = wrapLogin(LoginPage);

class AppComponent extends Component<DispatchProps & RouteComponentProps & StateProps, {}> {
    componentWillMount() {
        this.props.onTryAutoSignUp();
    }

    render() {
        let isAuthenticated = this.props.isAuthenticated;

        let result;
        if (isAuthenticated) {
            result = (
                <>
                    <Switch>
                        <Route path="/dns" exact component={DnsManagement} />
                        <Route path="/licenses" exact component={LicenseList} />
                        <Route path="/licenses/:id" exact component={LicenseDetails} />
                        <Route path="/upgrade-to-6-license" exact component={UpgradeLicense} />
                        <Redirect to="/dns" />
                    </Switch>
                </>
            );
        } else {
            result = (
                <>
                    <Route path="/upgrade-to-6-license" exact component={UpgradeLicense} />
                    <Route path="/" exact component={Login} />
                </>
            );
        }

        return <Switch>{result}</Switch>;
    }
}

interface DispatchProps {
    onTryAutoSignUp: () => void;
}

const mapDispatchToProps = (dispatch: CustomersThunkDispatch): DispatchProps => {
    return {
        onTryAutoSignUp: () => dispatch(actions.logingCheckState()),
    };
};

interface StateProps {
    isAuthenticated: boolean;
}

const mapStateToProps = ({ login }: AppState): StateProps => {
    return {
        isAuthenticated: login.token !== "",
    };
};

const App = connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(AppComponent);

export default withRouter(App);
