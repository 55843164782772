import React from 'react';
import { LicenseProperty } from '../../models/LicenseListModel'
import TextWithBalloon from '../../components/ui/TextWithBalloon'
import { timeFromNow } from '../../utils/misc';
import moment = require('moment');

interface Props {
    item: LicenseProperty;
}

const LicensePropertyComponent = (props: Props) => {
    let propValueElem;

    if (props.item.name !== "expires") {
        propValueElem = <div className="value">{props.item.value}</div>;
    } else {
        propValueElem = <div className="value">
            <TextWithBalloon 
                balloonText={ moment.utc(props.item.value).format("YYYY MMMM Do  hh:mm A") }>
                {timeFromNow(props.item.value)}
            </TextWithBalloon></div>;
    }

    return <div className="property-item">
        <div className="name">{props.item.name}</div>
        {propValueElem}        
    </div>
}

export default LicensePropertyComponent;
