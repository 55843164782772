import React from "react";

const LayoutMainWindow = (props) => {
    return <>
        <div className="bodyOnCanvas">
            <div className="flex-grow"></div>
            {props.children}
            <div className="flex-grow-2">  </div>
        </div>
        <small className="copyrightOnCanvas"> © RavenDB. All rights reserved {new Date().getFullYear()}</small>
    </>
}

export default LayoutMainWindow;
