export type API_ERROR = "API_ERROR";
export type HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export type ErrorActionType = "API_ERROR" | "HIDE_ERROR_MESSAGE" | "SHOW_ERROR_MESSAGE";

export type LOGIN_START = 'LOGIN_START';
export const LOGIN_START = 'LOGIN_START';
export type LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export type LOGOUT = 'LOGOUT';
export const LOGOUT = 'LOGOUT';

export type FETCH_MAPPING_START = 'FETCH_MAPPING_START';
export const FETCH_MAPPING_START = 'FETCH_MAPPING_START';
export type FETCH_MAPPING_SUCCESS = 'FETCH_MAPPING_SUCCESS';
export const FETCH_MAPPING_SUCCESS = 'FETCH_MAPPING_SUCCESS';
export type FETCH_MAPPING_FAIL = 'FETCH_MAPPING_FAIL';
export const FETCH_MAPPING_FAIL = 'FETCH_MAPPING_FAIL';

export type FETCH_LICENSE_DETAILS_START = 'FETCH_LICENSE_DETAILS_START';
export const FETCH_LICENSE_DETAILS_START = 'FETCH_LICENSE_DETAILS_START';
export type FETCH_LICENSE_DETAILS_SUCCESS = 'FETCH_LICENSE_DETAILS_SUCCESS';
export const FETCH_LICENSE_DETAILS_SUCCESS = 'FETCH_LICENSE_DETAILS_SUCCESS';
export type FETCH_LICENSE_DETAILS_FAIL = 'FETCH_LICENSE_DETAILS_FAIL';
export const FETCH_LICENSE_DETAILS_FAIL = 'FETCH_LICENSE_DETAILS_FAIL';

export type ADD_NODE = 'ADD_NODE';
export const ADD_NODE = 'ADD_NODE';
export type REMOVE_NODE = 'REMOVE_NODE';
export const REMOVE_NODE = 'REMOVE_NODE';
export type CHANGE_IP_NODE = 'CHANGE_IP_NODE';
export const CHANGE_IP_NODE = 'CHANGE_IP_NODE';
export type SAVE_NEW_NODE = 'SAVE_NEW_NODE';
export const SAVE_NEW_NODE = 'SAVE_NEW_NODE';
export type CHANGE_NODE_STATUS = 'CHANGE_NODE_STATUS';
export const CHANGE_NODE_STATUS = 'CHANGE_NODE_STATUS';
export type UNDO_REMOVE_NODE = 'UNDO_REMOVE_NODE';
export const UNDO_REMOVE_NODE = 'UNDO_REMOVE_NODE';
export type UNDO_NEW_NODE = 'UNDO_NEW_NODE';
export const UNDO_NEW_NODE = 'UNDO_NEW_NODE';

export type SAVE_CHANGES_DNS_START = 'SAVE_CHANGES_DNS_START ';
export const SAVE_CHANGES_DNS_START = 'SAVE_CHANGES_DNS_START ';
export type SAVE_CHANGES_DNS_SUCCESS = 'SAVE_CHANGES_DNS_SUCCESS';
export const SAVE_CHANGES_DNS_SUCCESS = 'SAVE_CHANGES_DNS_SUCCESS';
export type SAVE_CHANGES_DNS_FAIL = 'SAVE_CHANGES_DNS_FAIL';
export const SAVE_CHANGES_DNS_FAIL = 'SAVE_CHANGES_DNS_FAIL';

export type SORT_ALPHABET = 'SORT_ALPHABET';
export const SORT_ALPHABET = 'SORT_ALPHABET';

export type LEAVE_DNS_MANAGEMENT_PAGE = 'LEAVE_DNS_MANAGEMENT_PAGE';
export const LEAVE_DNS_MANAGEMENT_PAGE = 'LEAVE_DNS_MANAGEMENT_PAGE';
