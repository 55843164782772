import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
    link: string;
    ico: string;
    fullAccess: boolean;
    children: ReactNode
    classes?: string;
}

const NavigationItem = (props: Props) => {
    return <li>
        <NavLink className={props.classes} to={props.link} exact activeClassName="active">
            <i className={props.ico} ></i>
            {props.children}
        </NavLink>
    </li>
}

export default NavigationItem;
