import React, { useState } from "react";
import {LicenseUpgradeService} from "../../utils/Services";
import {SendFreeDeveloperLicenseRequest} from "../../models/UpgradeLicenseModel";

interface NotEligibleLicenseProps {
    email: string;
    didEmailChange: boolean;
    openUpdateContactModal: () => void;
    licenseUpgradeService: LicenseUpgradeService
    isUpdateContactErrored: boolean;
}

export function NotEligibleLicense(props: NotEligibleLicenseProps) {
    const { email, openUpdateContactModal, licenseUpgradeService, didEmailChange, isUpdateContactErrored } = props;
    
    const [isSendLicensePending, setIsSendLicensePending] = useState<boolean>(false);
    const [isSendLicenseSuccess, setIsSendLicenseSuccess] = useState<boolean>(false);
    const [isSendLicenseError, setIsSendLicenseError] = useState<Error>(null);
    const [acceptance, setAcceptance] = useState(false);
    
    const sendFreeDeveloperLicense = async () => {
        const dto: SendFreeDeveloperLicenseRequest = {
            email,
            termsAndConditionsAccepted: acceptance
        }
        
        try {
            setIsSendLicensePending(true);
            await licenseUpgradeService.sendFreeDeveloperLicense(dto);
            setIsSendLicenseSuccess(true);
        } catch (err) {
            setIsSendLicenseError(err);
        } finally {
            setIsSendLicensePending(false);
        }
    }
    
    const showSendFreeDeveloperLicenseButton = !isSendLicenseSuccess && !didEmailChange;
    const showChooseAnotherEmailButton = !isSendLicenseSuccess && !didEmailChange;
    const disableSendFreeDeveloperLicenseButton = isSendLicensePending || !acceptance;

    return (
        <div className="mainContainer vstack w-100">
            <a className="back-button" href="https://ravendb.net">
                <span>RavenDB.net</span>
            </a>
            <div className="hero-container hstack gap-4 align-items-center flex-wrap">
                <div className="lead-message vstack gap-4 flex-grow-0 align-self-end">
                    <div>
                        <h2 className="mb-4 fw-light">
                            We were unable to automatically convert your existing license to a RavenDB version 6.0
                            license.
                        </h2>

                        <h3 className="fw-light m-0">
                            Your dedicated sales representative will contact you shortly using <strong>{email}</strong>
                            {showChooseAnotherEmailButton && <button
                                onClick={openUpdateContactModal}
                                className="btn btn-sm btn-primary rounded-pill text-white ms-2"
                            >
                                Choose another email
                            </button>}
                        </h3>
                    </div>

                    {isUpdateContactErrored && <h4 className="text-center text-danger text">
                        There was a problem with sanding your request.
                    </h4>}
                    
                    {showSendFreeDeveloperLicenseButton && <>
                        <label className="d-inline mx-auto">
                            <input type="checkbox" checked={acceptance} onChange={() => setAcceptance(!acceptance)} className="me-2"/>
                            I accept the  <a target="_blank" href="https://ravendb.net/terms"><strong>terms and conditions</strong></a>
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary rounded-pill text-white btn-lg px-5 py-2 align-self-center"
                            disabled={disableSendFreeDeveloperLicenseButton}
                            onClick={sendFreeDeveloperLicense}
                        >
                            Get your free Developer license
                        </button>
                    </>
                    }
                    
                    {isSendLicenseSuccess && <h4 className="text-center text-success">Thank you! Your license will be sent shortly.</h4>}
                    {isSendLicenseError && !didEmailChange && <h4 className="text-center text-danger text">Sorry, something went wrong with your submitting, please try again later.</h4>}
                    
                    <div className="flex-grow-0">
                        <div className="text-center text-muted fs-5">
                            ** Please note that subscription adjustments, effective January 1st, 2024, apply whether you
                            upgrade now or later. For your subscription, this will be applied on the next payment date.
                            We're here to offer flexible solutions.
                        </div>
                    </div>
                    <div className="text-center">
                        Questions? Contact us at{" "}
                        <strong>
                            <a href="mailto:sales@ravendb.net">sales@ravendb.net</a>
                        </strong>
                        <br />
                        Thank you for your trust - we're happy to be a part of your success.
                    </div>
                </div>
                <div className="flex-grow-1" />
            </div>
            <div></div> {/* responsiveness fix*/}
        </div>
    );
}
