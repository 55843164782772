import React, { useState } from "react";
import { LicenseUpgradeService } from "../../utils/Services";
import { UpgradeLicenseRequest, UpgradeLicenseResponse } from "../../models/UpgradeLicenseModel";
import logoImg from "../../../img/upgrade/RavenDB-logo.svg";
import licenseImg from "../../../img/upgrade/license.svg";
import upgradeImg from "../../../img/upgrade/upgrade-arrow.svg";
import integrationsImg from "../../../img/upgrade/integrations.svg";
import performanceImg from "../../../img/upgrade/performance.svg";
import clusterImg from "../../../img/upgrade/cluster.svg";

const licenseExample = {
    Id: "12345678-1234-1234-1234-123456789101",
    Name: "RavenDB License Owner",
    Keys: ["aBcDeFgHiJkLmNoPqRsTuVwXyZ", "~!@#$%^&*()_+=-0987654321`"],
};

interface UpgradeFormProps {
    license: string;
    setLicense: (value: string) => void;

    onError: (error: string) => void;
    onNotEligible: (response: UpgradeLicenseResponse) => void;
    onConverted: (response: UpgradeLicenseResponse) => void;
    
    licenseUpgradeService: LicenseUpgradeService
}

export function UpgradeForm(props: UpgradeFormProps) {
    const { onError, onConverted, onNotEligible, license, setLicense, licenseUpgradeService } = props;
    const [inProgress, setInProgress] = useState<boolean>(false);

    const onConvertLicense = async (e: any) => {
        e.preventDefault();

        const dto: UpgradeLicenseRequest = {
            license,
        };

        try {
            setInProgress(true);
            const checkResponse = await licenseUpgradeService.convertLicense(dto);

            switch (checkResponse.status) {
                case "Invalid": {
                    onError("Invalid license.");
                    break;
                }
                case "NotEligible": {
                    onError(null);
                    onNotEligible(checkResponse);
                    break;
                }
                case "Converted": {
                    onError(null);
                    onConverted(checkResponse);
                    break;
                }
                case "AlreadyConverted": {
                    onError("This License was already converted to v6.0");
                    break;
                }
            }
        } catch (e) {
            onError("Unable to check license. Please try again later. ");
        } finally {
            setInProgress(false);
        }
    };

    return (
        <div className="mainContainer vstack gap-5 justify-content-between">
            <a className="back-button" href="https://ravendb.net">
                <span>RavenDB.net</span>
            </a>

            <div className="hero-container hstack gap-4 align-items-center flex-wrap">
                <div className="lead-message vstack gap-4 flex-grow-0 align-self-end">
                    <img alt="RavenDB" className="raven-logo" src={logoImg} />
                    <h1>License upgrade tool</h1>
                    <div>
                        <p>
                            Welcome to the RavenDB version 6.0 license upgrade wizard. This wizard will guide you
                            through the process of upgrading your existing RavenDB license to a license compatible with
                            RavenDB version 6.0.
                        </p>
                    </div>
                </div>
                <div className="flex-grow-1" />
                <div className="card form-card bg-light text-dark align-self-center">
                    <div className="card-body p-4">
                        <div className="login-center vstack gap-4">
                            <div className="hstack gap-4 align-items-center">
                                <img alt="" className="icon-size" src={licenseImg} />
                                <div>
                                    <b>Please enter your license key in the form</b>
                                    <span>
                                        {" "}
                                        and click the &quot;Upgrade License&quot; button to start the upgrade process.
                                    </span>
                                </div>
                            </div>

                            <form onSubmit={onConvertLicense} className="vstack gap-4">
                                <textarea
                                    required
                                    onChange={(e) => setLicense(e.target.value)}
                                    value={license}
                                    placeholder={`e.g. ${JSON.stringify(licenseExample, null, 4)}`}
                                    rows={7}
                                    className="form-control p-3"
                                />

                                <button
                                    className="btn btn-primary rounded-pill text-white btn-lg px-5 py-2 align-self-center d-flex align-items-center gap-2"
                                    disabled={inProgress}
                                >
                                    {" "}
                                    <img alt="" src={upgradeImg} className="image-size" />
                                    <span>Upgrade License</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1" />
            </div>

            <div className="vstack gap-4 flex-grow-0">
                <div>
                    <p className="lead text-center">
                        <strong>RavenDB version 6.0</strong>
                        <span> has many new features that we are excited for you to try and explore.</span>
                    </p>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <img alt="" className="icon-size mb-3" src={integrationsImg} />
                        <h3 className="text-uppercase mb-3">Smart Integrations</h3>
                        <p>Elevate data management with Kafka and RabbitMQ.</p>
                    </div>
                    <div className="col-12 col-sm-4">
                        <img alt="" className="icon-size mb-3" src={performanceImg} />
                        <h3 className="text-uppercase mb-3">Lightning-Fast Performance</h3>
                        <p>Boost your applications&apos; responsiveness with Corax technology.</p>
                    </div>
                    <div className="col-12 col-sm-4">
                        <img alt="" className="icon-size mb-3" src={clusterImg} />
                        <h3 className="text-uppercase mb-3">Seamless Scalability</h3>
                        <p>Ensure outstanding performance as your data grows with advanced sharding.</p>
                    </div>
                </div>
                <div className="text-center">
                    <a
                        href="https://ravendb.net/why-ravendb/whats-new"
                        className="btn btn-outline-secondary rounded-pill px-5 py-2"
                    >
                        <strong>learn more about version 6.0</strong>
                    </a>
                </div>
                {/* <div className="text-center">
                    <a
                        href="https://ravendb.net/v6-new-version"
                        className="btn btn-outline-secondary rounded-pill px-5 py-2"
                    >
                        <strong>Learn more and visit our V6 mini-site</strong>
                    </a>
                </div> */}
            </div>
        </div>
    );
}
