import React, { PureComponent } from 'react';
import Button from '../ui/Button';
import { connect } from 'react-redux';
import { AppState } from '../../store/state';
import { CustomersThunkDispatch } from '../../store';
import { logoutGotClicked } from '../../store/actions';

import logoSvg from '../../../img/logo.svg';

interface StateProps {
    logged: boolean;
}

interface DispatchProps {
    onLogoutClick?: () => void;
}

type Props = StateProps & DispatchProps;

class TopBarComponent extends PureComponent<Props> {

    render() {
        const logOffButton = this.props.logged &&
            <Button
                classes="logout btn btn-default"
                ico="icon-logout"
                onClick={this.props.onLogoutClick}>
                Logout
        </Button>;

        return <div className="header">
            <div>
                <i className="logo">
                    <img src={logoSvg} />
                </i>
            </div>
            <div className="flex-grow"></div>
            <div className="progress"></div>
            <div className="dropdown"></div>
            {logOffButton}
        </div>
    }
}

function mapStateToProps({ login }: AppState): StateProps {
    return {
        logged: login.token !== ''
    };
}

function mapDispatchToProps(dispatch: CustomersThunkDispatch): DispatchProps {
    return {
        onLogoutClick: () => dispatch(logoutGotClicked())
    }
}

export const TopBar = connect<StateProps, DispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
)(TopBarComponent);
