import { ApiClient } from "./ApiClient";
import { LoginDataModel } from "../models/LoginDataModel";
import { DnsRequest } from "../models/DnsRequest";
import UserNodes from "../models/UserNodes";
import { LicensesListModel } from "../models/LicenseListModel";
import {
    SendFreeDeveloperLicenseRequest,
    UpgradeLicenseContactFormRequest,
    UpgradeLicenseContactFormResponse,
    UpgradeLicenseRequest,
    UpgradeLicenseResponse,
} from "../models/UpgradeLicenseModel";

abstract class Service {
    constructor(private apiUrl: string) {}

    private fullUrl(url: string) {
        return `${this.apiUrl}/${url}`;
    }

    protected get<T>(url: string, token: string): Promise<T> {
        return ApiClient.get(this.fullUrl(url), token);
    }

    protected post<TInput, TOutput>(url: string, token: string, data: TInput): Promise<TOutput> {
        return ApiClient.post(this.fullUrl(url), token, data);
    }
}

export class LicenseUpgradeService extends Service {
    constructor() {
        super("/api");
    }
    async convertLicense(dto: UpgradeLicenseRequest): Promise<UpgradeLicenseResponse> {
        return this.post<UpgradeLicenseRequest, UpgradeLicenseResponse>("license-upgrade/convert", undefined, dto);
    }

    async contactForm(dto: UpgradeLicenseContactFormRequest): Promise<UpgradeLicenseContactFormResponse> {
        return this.post<UpgradeLicenseContactFormRequest, UpgradeLicenseContactFormResponse>(
            "license-upgrade/contact",
            undefined,
            dto
        );
    }

    async sendFreeDeveloperLicense(dto: SendFreeDeveloperLicenseRequest): Promise<unknown> {
        return this.post(
            "license-upgrade/send-developer-license",
            undefined,
            dto
        );
    }
}

export class LicenseInfoService extends Service {
    constructor() {
        super("/api");
    }

    async getLicenseInfo(token: string): Promise<LicensesListModel> {
        return this.get<LicensesListModel>("license-info", token);
    }
}

export class LoginService extends Service {
    constructor() {
        super("/api");
    }

    async login(license: string): Promise<LoginDataModel> {
        return this.post<string, LoginDataModel>("login", "", license);
    }

    async logout(): Promise<LoginDataModel> {
        return this.get<LoginDataModel>("logout", "");
    }
}

export class DnsManagementService extends Service {
    constructor() {
        super("/api");
    }

    getUserDomainInfo(token: string): Promise<UserNodes> {
        return this.get("dns-management", token);
    }

    saveUserDomainChanges(submitNodesChanges: DnsRequest, token: string): Promise<UserNodes> {
        return this.post("dns-management/update-nodes", token, JSON.stringify(submitNodesChanges));
    }
}
