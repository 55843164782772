import * as actionTypes from "./actionTypes";

export interface ErrorAction {
    type: actionTypes.ErrorActionType;
}

export interface ShowErrorMessageAction extends ErrorAction {
    errorMessage: string;
}


export function hideErrorMessage(): ErrorAction {
    return { type: "HIDE_ERROR_MESSAGE" };
} 

export function showErrorMessage(errorMessage: string): ShowErrorMessageAction {
    return { 
        type: "SHOW_ERROR_MESSAGE",
        errorMessage
    };
}
