import React, { useState } from "react";
import { UpgradeContactForm } from "./UpgradeContactForm";
import userImg from "../../../img/upgrade/user.svg";
import upgradeImg from "../../../img/upgrade/upgrade-arrow.svg";
import mailImg from "../../../img/upgrade/mail.svg";
import packageImg from "../../../img/upgrade/package.svg";

interface ConvertedLicenseProps {
    email: string;
    openUpdateContactModal: () => void;
}

export function ConvertedLicense(props: ConvertedLicenseProps) {
    const { email, openUpdateContactModal } = props;

    return (
        <div className="mainContainer vstack gap-5 justify-content-between w-100">
            <a className="back-button" href="https://ravendb.net">
                <span>RavenDB.net</span>
            </a>
            <div className="hero-container hstack gap-4 align-items-center flex-wrap">
                <div className="lead-message vstack gap-6 flex-grow-0 align-self-end">
                    <div>
                        <h2 className="mb-5 fw-light">
                            Your license has been upgraded to{" "}
                            <strong className="text-secondary nobr">RavenDB version 6.0</strong>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 text-center">
                            <img alt="" className="icon-size mb-3" src={mailImg} />
                            <div>
                                The license has been sent to your email address{" "}
                                <strong className="text-secondary">{email}</strong> and should show up there shortly.
                                <button
                                    onClick={openUpdateContactModal}
                                    className="btn btn-sm btn-primary rounded-pill text-white"
                                >
                                    Choose another email
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 text-center">
                            <img alt="" className="icon-size mb-3" src={packageImg} />
                            <div>
                                You can find upgrade instructions to RavenDB version 6.0 in the{" "}
                                <strong>
                                    <a
                                        href="https://ravendb.net/docs/article-page/6.0/csharp/start/installation/upgrading-to-new-version"
                                        className="nobr"
                                    >
                                        following link
                                    </a>
                                </strong>
                                .
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p className="lead mb-0 text-center">
                                You can now upgrade your RavenDB instances to version 6.0 and enjoy our best version
                                yet.
                            </p>
                        </div>
                    </div>
                    <div className="flex-grow-0">
                        <div className="text-center text-muted fs-5">
                            ** Please note that subscription adjustments, effective January 1st, 2024, apply whether you
                            upgrade now or later. For your subscription, this will be applied on the next payment date.
                            We're here to offer flexible solutions.
                        </div>
                    </div>
                    <div className="text-center">
                        Questions? Contact us at{" "}
                        <strong>
                            <a href="mailto:sales@ravendb.net">sales@ravendb.net</a>
                        </strong>
                        <br />
                        Thank you for your trust - we're happy to be a part of your success.
                    </div>
                </div>
                <div className="flex-grow-1" />
            </div>
            <div></div> {/* responsiveness fix*/}
        </div>
    );
}
