import React from "react";
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from "../../store/state";
import LayoutMainWindow from '../layout/MainWindow';
import { TopBar } from '../navigation/TopBar';
import FancyBackground from "../../utils/FancyBackground";

interface LoginComponentState {
    licenseForm: string;
}

interface DispatchProps {
    onLogin: (licence: string) => void;
}

interface StateProps {
    loading: boolean;
    localToken: string;
    errorMessage: any;
}

type Props = DispatchProps & RouteComponentProps & StateProps;

class LoginPageComponent extends React.Component<Props, LoginComponentState>{
    private canvasRef: React.RefObject<HTMLCanvasElement>;
    state = {
        licenseForm: ''
    }

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    submitHandler = (event: any) => {
        event.preventDefault();
        try {
            if (this.state.licenseForm === '')
                alert("Please Enter License");
            else
                this.props.onLogin(this.state.licenseForm);

        } catch (error) {
            //TODO: 
            console.log("error while login");
        }
    };
    handleChange = (event) => {
        this.setState({ licenseForm: event.target.value });
    };
    render() {
        return (
            <>

                <TopBar />
                <canvas ref={this.canvasRef} />
                <LayoutMainWindow>
                    <div className="login-center">
                        <form onSubmit={this.submitHandler.bind(this)}>
                            {this.props.errorMessage ? this.props.errorMessage.response : null}
                            <h1 className="margin-bottom">Sign in using license key</h1>
                            <textarea onChange={this.handleChange} placeholder="Enter your license" rows={10} />
                            <button className="btn btn-primary specialButton"><span>SIGN IN</span></button>
                            {/* <p className="text-center">OR</p>
                            <button className="btn btn-default"><i className="icon-mail"></i>SIGN IN USING E-MAIL</button> */}
                        </form>
                    </div>
                </LayoutMainWindow>
            </>
        );
    }

    componentDidMount() {
        FancyBackground(this.canvasRef, false);
    }
    
    componentWillUnmount() {
        FancyBackground(this.canvasRef, true);
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (license: string) => dispatch(actions.login(license))
    };
};
const mapStateToProps = ({ login }: AppState): StateProps => {
    const { token, loadingLogin, error } = login;
    return {
        localToken: token,
        loading: loadingLogin,
        errorMessage: error
    };
};

export const LoginPage = connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
