import * as React from "react";
import { TopBar } from "./navigation/TopBar";
import SideBar from "./navigation/SideBar";
import ErrorModal from "./ui/modals/ErrorModal";

export default function loginWithLayout(WrappedComponent: React.ComponentType) {
    return class LoginWithLayoutComponent extends React.Component {
        render() {
            return <>
                <div>
                    <ErrorModal />
                    <WrappedComponent />
                </div>
            </>;
        }
    };
}
