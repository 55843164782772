import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import NodeList from './NodeList';
import EditDomainPanelHeader from './EditDomainPanelHeader';
import * as actions from '../../store/actions/index';
import { UserDomain } from '../../models/UserNodes/UserNodesMappings';
import { DnsRequest } from '../../models/DnsRequest';
import { CustomersThunkDispatch } from '../../store';
import { AppState } from '../../store/state';
import { LoginState } from '../../store/state/LoginState';
import { SaveChangesStatus } from '../../models/SaveChangesStatus';
import { DnsManagementService } from '../../utils/Services';

interface Props {
    domainToBuild: UserDomain;
    domainIndex: number;
    docId: string;
    addNewNode: (domainIndex: number) => void;
    updateNumberOfUnsavedBox: (inc: boolean) => void;
    fetchMappingsForOneBox: () => void;
    isBlocking: (mask: number, changed: boolean) => void;
}

interface DispatchProps {
    saveChanges: (submitNodesChanges: DnsRequest, token: string) => void;
}

interface PropsToState {
    loginState: LoginState
}

interface State {
    pendingReqDelete: string[],
    pendingReqUpdate: object,
    saveButtonStatus: SaveChangesStatus
    canLeaveThePage: boolean;
    mask: number;
}

class EditDomainComponent extends PureComponent<Props & DispatchProps & PropsToState, State> {
    state: State = {
        pendingReqDelete: [],
        pendingReqUpdate: {},
        saveButtonStatus: 'Available',
        canLeaveThePage: true,
        mask: Math.pow(2, this.props.domainIndex)
    }
    componentDidUpdate = () => {
        const hasPendingChanges = this.state.pendingReqDelete.length === 0 
            && Object.keys(this.state.pendingReqUpdate).length === 0;
        if (this.state.canLeaveThePage !== hasPendingChanges) {
            this.props.isBlocking(this.state.mask, !this.state.canLeaveThePage);
            this.setState({ canLeaveThePage: !this.state.canLeaveThePage });
        }
    }

    removeNodesHandler = (fullDomainName: string) => {
        this.setState({ pendingReqDelete: [...this.state.pendingReqDelete, fullDomainName] });
    }

    removeFromUpdateNodesHendler = (node: string) => {
        let res2 = {};
        Object.keys(this.state.pendingReqUpdate)
            .map(fullDomainName => {
                if (fullDomainName != node)
                    res2[fullDomainName] = this.state.pendingReqUpdate[fullDomainName];
            })
        this.setState({ pendingReqUpdate: res2 });
    }

    undoDeleteNodesHandler = (fullDomainName: string) => {
        const updatePending = [...this.state.pendingReqDelete];
        updatePending.splice(this.state.pendingReqDelete.indexOf(fullDomainName), 1);
        this.setState({ pendingReqDelete: updatePending });
    }

    addNewNodeHandler = () => {
        this.props.addNewNode(this.props.domainIndex);
    }

    checkDuplicatNodesHandler = (fullDomainName: string, nodeIndex: number): boolean => {
        let result = true;
        this.props.domainToBuild.domainsData.forEach(node => {
            if (node.fullNodeDomainName === fullDomainName 
                && this.props.domainToBuild.domainsData.indexOf(node) != nodeIndex)
                result = false;
        })
        return result;
    }

    updateNodesHandler = (fullDomainName: string, ips: string[]) => {
        this.setState({
            pendingReqUpdate: {
                ...this.state.pendingReqUpdate,
                [fullDomainName]: ips
            }
        });
    }

    isUniqueIpAcrossNodes = (nodeName: string, ips: string[]): boolean => {
        let result = true;
        this.props.domainToBuild.domainsData.forEach(node => {
            if (node.fullNodeDomainName != nodeName)
                node.ips.forEach(ip => {
                    ips.forEach(newIp => {
                        if (newIp === ip)
                            result = false;
                    })
                })
        })
        return result;
    }

    saveChangesChangesHandler = async () => {
        const service = new DnsManagementService();
        this.setState({ saveButtonStatus: 'Saving' });
        try {
            const result = await service.saveUserDomainChanges({ 
                NodesToDelete: this.state.pendingReqDelete, 
                NodesToUpdate: this.state.pendingReqUpdate, 
                id: this.props.docId 
            }, this.props.loginState.token);
            console.log(result.error);
            if (typeof result.error === 'undefined') {
                this.setState({ saveButtonStatus: 'Success' });
                setTimeout(() => {
                    this.setState({
                        saveButtonStatus: 'Available',
                        pendingReqDelete: [],
                        pendingReqUpdate: {}
                    });
                    this.props.fetchMappingsForOneBox();
                }, 0.05 * 60 * 1000);
            } else
                this.setState({ saveButtonStatus: 'Error' });
        }
        catch (error) {
            this.setState({
                saveButtonStatus: "Error"
            });
        }
    }

    render() {
        return <>
            <div className="panel license-item ">
                <EditDomainPanelHeader
                    rootDomain={this.props.domainToBuild.domainName}
                    clickAbleSaveButton={this.state.canLeaveThePage}
                    saveChanges={this.saveChangesChangesHandler.bind(this)}
                    addNewNode={this.addNewNodeHandler.bind(this)}
                    saveButtonStatus={this.state.saveButtonStatus} />
                <div className="panel-addon">
                    <div className="nodes-container">
                        <div className="row">
                            <div className="node-label">NODE TAG</div>
                            <div className="node-label">IP</div>
                            <div className="node-label">LAST UPDATE</div>
                            <div className="node-label">ACTIONS</div>
                        </div>
                        {this.props.domainToBuild.domainsData.map((nodes, index) => {
                            return <NodeList
                                key={index}
                                node={nodes}
                                subDomain={"." + this.props.domainToBuild.domainName}
                                nodeIndex={index}
                                checkDuplicatNodes={this.checkDuplicatNodesHandler.bind(this)}
                                onRemoveFromUpdate={this.removeFromUpdateNodesHendler.bind(this)}
                                domainIndex={this.props.domainIndex}
                                onDeleteClick={this.removeNodesHandler.bind(this)}
                                unDoDeleteClicked={this.undoDeleteNodesHandler.bind(this)}
                                changeIpClicked={this.updateNodesHandler.bind(this)}
                                isUniqueIpAcrossNodes={this.isUniqueIpAcrossNodes.bind(this)} />
                        })}
                    </div>
                </div>
            </div>
        </>
    }
}

const mapDispatchToProps = (dispatch: CustomersThunkDispatch): DispatchProps => {
    return {
        saveChanges: (submitNodesChanges: DnsRequest, token: string) => 
            dispatch(actions.saveChangesToNodes(submitNodesChanges, token))

    }
}

const mapPropsToState = ({ login }: AppState) => {
    return {
        loginState: login
    }
}

const EditDomainPanel = connect<PropsToState, DispatchProps, {}>(mapPropsToState, mapDispatchToProps)(EditDomainComponent);
export default EditDomainPanel;
