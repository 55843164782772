import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
export class ApiClient {
    private static async prepareRequest<T>(config: AxiosRequestConfig, relativeUrl: string): Promise<T> {
        const url = this.getApiUrl(relativeUrl);
        const headers = config.headers || {};

        const axiosParams = {
            ...config,
            headers,
            url,
        };
        const res = (await axios(axiosParams)) as AxiosResponse;
        const responseData = res.data;

        return responseData;
    }

    static getApiUrl(suffix: string) {
        return window.location.origin + suffix;
    }

    private static getCommonHeaders({ authToken }) {
        if (authToken) {
            return { Authorization: `Bearer ${authToken}` };
        }

        return {};
    }

    static post<TInput, TOutput>(url: string, authToken: string, data: TInput): Promise<TOutput> {
        return this.prepareRequest<TOutput>(
            {
                method: "post",
                data: data,
                headers: this.getCommonHeaders({ authToken }),
            },
            url
        );
    }

    static get<T>(url: string, authToken: string): Promise<T> {
        return this.prepareRequest<T>(
            {
                method: "get",
                headers: this.getCommonHeaders({ authToken }),
            },
            url
        );
    }
}
