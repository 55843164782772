import * as actionTypes from './actionTypes';
import { LicensesListModel } from '../../models/LicenseListModel';
import { CustomersThunkAction } from '.';
import { LicenseInfoService } from '../../utils/Services';
import { CustomersThunkDispatch } from '..';

export const fetchLicenseDetailsStart = (): fetchLicenseDetailsStart => {
    return {
        type: actionTypes.FETCH_LICENSE_DETAILS_START
    };
};

export const fetchLicenseDetailsSuccess = (result: LicensesListModel): fetchLicenseDetailsSuccess => {
    return {
        type: actionTypes.FETCH_LICENSE_DETAILS_SUCCESS,
        LicenseDetailsData: result
    };
};

export const fetchLicenseDetailsFail = (error: Error): fetchLicenseDetailsFail => {
    return {
        type: actionTypes.FETCH_LICENSE_DETAILS_FAIL,
        error: error
    };
};

const service = new LicenseInfoService();
export const fetchLicenseDetails = (): CustomersThunkAction => {
    return async (dispatch: CustomersThunkDispatch, getState) => {
        const { login } = getState();
        dispatch(fetchLicenseDetailsStart());
        try {
            const data = await service.getLicenseInfo(login.token);
            dispatch(fetchLicenseDetailsSuccess(data));
        } catch (error) {
            dispatch(fetchLicenseDetailsFail(error as Error));
        }
    }
}

export interface fetchLicenseDetailsStart {
    type: actionTypes.FETCH_LICENSE_DETAILS_START;
}

export interface fetchLicenseDetailsSuccess {
    type: actionTypes.FETCH_LICENSE_DETAILS_SUCCESS;
    LicenseDetailsData: LicensesListModel;
}

export interface fetchLicenseDetailsFail {
    type: actionTypes.FETCH_LICENSE_DETAILS_FAIL;
    error: any;
}

export type LicenseDetailsActions = fetchLicenseDetailsStart | fetchLicenseDetailsSuccess | fetchLicenseDetailsFail ;
