import { modifyState } from "../state";
import { ErrorAction, ShowErrorMessageAction } from "../actions/errorActions";
import { ErrorState } from "../state/ErrorState";

export function errorReducer(
    state: ErrorState = { errorMessage: null, error: null }, 
    action: ErrorAction): ErrorState {
    switch (action.type) {
        case "SHOW_ERROR_MESSAGE":
            const showMsgAction = action as ShowErrorMessageAction;
            return modifyState(state, s => s.errorMessage = showMsgAction.errorMessage);
        case "HIDE_ERROR_MESSAGE":
            return modifyState(state, s => s.errorMessage = null);
    }

    return state;
}
