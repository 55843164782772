import * as actionTypes from './actionTypes';
import { CustomersThunkAction } from "./index";
import { CustomersThunkDispatch } from "../index";
import { LicenseInfoService, DnsManagementService } from '../../utils/Services';
import UserNodesDataModelDto from '../../models/UserNodes';
import { NodeStatus } from '../../models/UserNodes/NodeStatus';
import { DnsRequest } from '../../models/DnsRequest';
import { showErrorMessage } from './errorActions';

export const fetchMappingStart = (): fetchMappingStart => {
    return {
        type: actionTypes.FETCH_MAPPING_START
    };
};

export const fetchMappingSuccess = (result: UserNodesDataModelDto, domainIndex: number): fetchMappingSuccess => {
    return {
        type: actionTypes.FETCH_MAPPING_SUCCESS,
        UserNodesData: result,
        domainIndex: domainIndex
    };
};

export const fetchMappingFail = (error: any): fetchMappingFail => {
    return {
        type: actionTypes.FETCH_MAPPING_FAIL,
        error: error
    };
};

export const addNode = (domainIndex: number): addNode => {
    return {
        type: actionTypes.ADD_NODE,
        domainIndex
    };
};

export const removeNode = (domainIndex: number, nodeIndex: number): removeNode => {
    return {
        type: actionTypes.REMOVE_NODE,
        domainIndex,
        nodeIndex
    };
};

export const chageNodeIp = (domainIndex: number, nodeIndex: number, ips: string[]): chageNodeIp => {
    return {
        type: actionTypes.CHANGE_IP_NODE,
        domainIndex,
        nodeIndex,
        ips
    };
};

export const changeNodeStatus = (domainIndex: number, nodeIndex: number, status: NodeStatus): changeNodeStatus => {
    return {
        type: actionTypes.CHANGE_NODE_STATUS,
        domainIndex,
        nodeIndex,
        status
    };
};

export const undoRemoveNode = (domainIndex: number, nodeIndex: number): undoRemoveNode => {
    return {
        type: actionTypes.UNDO_REMOVE_NODE,
        domainIndex,
        nodeIndex
    };
};


export const updateNewNode = (
    domainIndex: number, nodeIndex: number, fullDomainName: string, ips: string[]): updateNewNode => {
    return {
        type: actionTypes.SAVE_NEW_NODE,
        domainIndex,
        nodeIndex,
        fullDomainName,
        ips
    };
};

export const undoNewNode = (domainIndex: number, nodeIndex: number): undoNewNode => {
    return {
        type: actionTypes.UNDO_NEW_NODE,
        domainIndex,
        nodeIndex
    };
};

export const saveChangesDnsStart = (): saveChangesDnsStart => {
    return {
        type: actionTypes.SAVE_CHANGES_DNS_START
    };
};

export const sortNodes = (): sortNodes => {
    return {
        type: actionTypes.SORT_ALPHABET
    };
};

export const saveChangesDnsSuccess = (): saveChangesDnsSuccess => {
    return {
        type: actionTypes.SAVE_CHANGES_DNS_SUCCESS
    };
};

export const saveChangesDnsFail = (error: any): saveChangesDnsFail => {
    return {
        type: actionTypes.SAVE_CHANGES_DNS_FAIL,
        error: error
    };
};

export const leaveDnsManagementPage = (): leaveDnsManagementPage => {
    return {
        type: actionTypes.LEAVE_DNS_MANAGEMENT_PAGE,
    };
};

const dnsManagementService = new DnsManagementService();

export const fetchMapping = (token: string, domainIndex: number = null): CustomersThunkAction => {
    return async (dispatch: CustomersThunkDispatch) => {
        dispatch(fetchMappingStart());
        try {
            const result = await dnsManagementService.getUserDomainInfo(token);
            dispatch(fetchMappingSuccess(result, domainIndex));
            dispatch(sortNodes());
        } catch (error) {
            dispatch(fetchMappingFail(error));
            dispatch(showErrorMessage(`Error fetching DNS mapping: ${error.message}.`));
        }
    };
};

export const saveChangesToNodes = (submitNodesChanges: DnsRequest, token: string): CustomersThunkAction => {
    return async (dispatch: CustomersThunkDispatch) => {
        dispatch(saveChangesDnsStart());
        try {
            await dnsManagementService.saveUserDomainChanges(submitNodesChanges, token);
            dispatch(saveChangesDnsSuccess());
        } catch (error) {
            dispatch(saveChangesDnsFail(error));
            dispatch(showErrorMessage(`Error saving DNS changes: ${error.message}.`));
        }
    };
};

export interface fetchMappingStart {
    type: actionTypes.FETCH_MAPPING_START;
}

export interface fetchMappingSuccess {
    type: actionTypes.FETCH_MAPPING_SUCCESS
    UserNodesData: UserNodesDataModelDto;
    domainIndex: number;
}

export interface fetchMappingFail {
    type: actionTypes.FETCH_MAPPING_FAIL;
    error: any;
}

export interface addNode {
    type: actionTypes.ADD_NODE;
    domainIndex: number;
}

export interface removeNode {
    type: actionTypes.REMOVE_NODE;
    domainIndex: number;
    nodeIndex: number;
}

export interface undoRemoveNode {
    type: actionTypes.UNDO_REMOVE_NODE;
    domainIndex: number;
    nodeIndex: number;
}

export interface undoNewNode {
    type: actionTypes.UNDO_NEW_NODE,
    domainIndex: number,
    nodeIndex: number
}

export interface chageNodeIp {
    type: actionTypes.CHANGE_IP_NODE;
    domainIndex: number;
    nodeIndex: number;
    ips: string[];
}

export interface updateNewNode {
    type: actionTypes.SAVE_NEW_NODE;
    domainIndex: number;
    nodeIndex: number;
    fullDomainName: string;
    ips: string[];
}

export interface changeNodeStatus {
    type: actionTypes.CHANGE_NODE_STATUS;
    domainIndex: number;
    nodeIndex: number;
    status: NodeStatus;
}

export interface sortNodes {
    type: actionTypes.SORT_ALPHABET
}

export interface saveChangesDnsStart {
    type: actionTypes.SAVE_CHANGES_DNS_START;
}

export interface saveChangesDnsSuccess {
    type: actionTypes.SAVE_CHANGES_DNS_SUCCESS;
}

export interface saveChangesDnsFail {
    type: actionTypes.SAVE_CHANGES_DNS_FAIL;
    error: any;
}

export interface leaveDnsManagementPage {
    type: actionTypes.LEAVE_DNS_MANAGEMENT_PAGE;
}

export type FetchMappingAction = fetchMappingStart | fetchMappingSuccess | fetchMappingFail |
    saveChangesDnsStart | saveChangesDnsSuccess | saveChangesDnsFail |
    addNode | removeNode | chageNodeIp | changeNodeStatus | undoRemoveNode | updateNewNode | undoNewNode | sortNodes | leaveDnsManagementPage;
