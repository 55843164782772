import React from "react";

const UpgradeLicenseWindow = (props) => {
    return (
        <>
            <div className="bodyOnCanvas">{props.children}</div>
            <small className="copyrightOnCanvas"> © RavenDB. All rights reserved {new Date().getFullYear()}</small>
        </>
    );
};

export default UpgradeLicenseWindow;
