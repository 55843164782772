import { LicenseDetailsState } from '../state/LicenseDetailsState';
import { LicenseDetailsActions } from '../actions/licenseDetailsActions';
import * as actions from '../actions/actionTypes';
import { modifyState } from '../state';

const initState: LicenseDetailsState = {
    licenses: null,
    loadingLicenseDetails: false
}

const fetchLicenseDetailsStart = (state: LicenseDetailsState):LicenseDetailsState => {
    return modifyState(state, s => {
        s.loadingLicenseDetails = true
    });
}

const fetchLicenseDetailsSuccess = (state: LicenseDetailsState, data):LicenseDetailsState => {
    return modifyState(state, s => {
        s.licenses = data
        s.loadingLicenseDetails = false
    });
}

const fetchLicenseDetailsFail = (state: LicenseDetailsState):LicenseDetailsState => {
    return modifyState(state, s => {
        s.loadingLicenseDetails = false
    });
}

const licenseDetailsReducer = (state: LicenseDetailsState = initState, action: LicenseDetailsActions): LicenseDetailsState => {
    switch (action.type) {
        case (actions.FETCH_LICENSE_DETAILS_START):
            return fetchLicenseDetailsStart(state);
        case (actions.FETCH_LICENSE_DETAILS_SUCCESS):
            return fetchLicenseDetailsSuccess(state, action.LicenseDetailsData);
        case (actions.FETCH_LICENSE_DETAILS_FAIL):
            return fetchLicenseDetailsFail(state);
        default:
            return state;
    }
}

export default licenseDetailsReducer;
